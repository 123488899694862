import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/ads';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import { RootState, useAppDispatch } from '@/store';
import { getAllAdvertiser } from '@/store/advertise/functions';
import { debounce } from '@/utils/debounce';

const Advertise = ({ advertiseTabs, search }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
  });

  const advertisers = useSelector((state: RootState) => state.ads.advertisers);

  const pagination = useSelector((state: RootState) => state?.ads?.pagination);

  // useEffect(() => {
  //   dispatch(getAllAdvertiser());
  // }, [dispatch]);

  const tableData = {
    headings: [
      // { key: 'id', label: 'Id' },
      { key: 'name', label: 'Full Name' },
      { key: 'email', label: 'Email' },
      { key: 'phoneNumber', label: 'Phone Number' },
      { key: 'website', label: 'Website' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(advertisers)
      ? advertisers?.map((advertise) => ({
          id: advertise?._id,
          name: advertise?.fullName,
          email: advertise?.email,
          phoneNumber: advertise?.phoneNumber,
          website: advertise?.website,
          actions: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(`advertiser/${advertise?._id}`)}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const fetchAdvertise = useCallback(() => {
    dispatch(getAllAdvertiser(paginationParams));
  }, [dispatch, paginationParams]);

  useEffect(() => {
    fetchAdvertise();
  }, [fetchAdvertise]);
  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };

  const handleNextPage = (page) => {
    setPaginationParams({ ...paginationParams, page: page });
  };

  const handleLastPage = (page: number) => {
    setPaginationParams({ ...paginationParams, page });
  };

  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setPaginationParams((prev) => ({
        ...prev,
        search: searchTerm,
        page: 1,
      }));
    }, 300),
    []
  );

  useEffect(() => {
    if (search) {
      debouncedSearch(search);
    }
  }, [search]);
  return (
    <div>
      <Table
        customRow={advertiseTabs}
        tableData={tableData}
        noData="No data to display"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />
      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        onLast={handleLastPage}
        onNext={handleNextPage}
        totalPages={pagination?.totalPages}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
};

export default Advertise;
