import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/documents';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import DeleteModal from '@/components/DeleteModal';
import Pagination from '@/components/Pagination';
import { docsAnalyticsKeys } from '@/constants';
import { useRoot } from '@/context/RootProvider';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch, useAppSelector } from '@/store';
import {
  deleteDocument,
  getAllDocumentWithAnalytics,
} from '@/store/documents/functions';
import usePaginationStore from '@/storeZustand/paginationStore';
import { toCapitalCase } from '@/utils/conversion';
import { debounce } from '@/utils/debounce';

import { SearchInputWrapperStyled } from './style';

type Props = {};
export const regionMapping = {
  ie: 'Ireland',
  uk: 'United Kingdom',
};

const Documents = (_props: Props) => {
  const { setLimit, getLimit } = usePaginationStore();
  const [apiError, setApiError] = useState('');
  const { region } = useRoot();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const documentsState = useAppSelector((state: RootState) => state.documents);
  const [docId, _setDocId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { documents, loading, pagination, analytics } = documentsState;

  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page') || '1', 10);
  const initialLimit = parseInt(
    queryParams.get('limit') || getLimit('documents'),
    10
  );
  const initialSearch = queryParams.get('search') || '';
  const initialFilter = queryParams.get('filter[type]') || null;
  const [cardKey, setCardKey] = useState<string | null>(initialFilter);
  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: initialPage,
    limit: initialLimit,
    search: initialSearch,
    filter: initialFilter ? { type: initialFilter } : undefined,
  });

  const fetchDocuments = (myParams: PaginationType) => {
    const noEmptyParams = Object.entries(myParams).reduce((acc, val) => {
      if (!val[1]) {
        return acc;
      }
      acc = { ...acc, [val[0]]: val[1] };
      return acc;
    }, {});
    dispatch(getAllDocumentWithAnalytics(noEmptyParams));
  };

  const delayedFetch = useCallback(debounce(fetchDocuments, 300), []);

  useEffect(() => {
    if (documentsState.error) {
      setApiError(documentsState.error);
    }
  }, [documentsState.error]);

  useEffect(() => {
    fetchDocuments(paginationParams);
  }, [dispatch, region]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set('page', paginationParams.page.toString());
    params.set('limit', paginationParams.limit.toString());
    if (paginationParams.search) {
      params.set('search', paginationParams.search);
    }
    if (cardKey) {
      params.set('tab', cardKey);
    }
    navigate(`?${params.toString()}`, { replace: true });
  }, [paginationParams, cardKey, navigate]);

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Name/Title' },
      { key: 'documentType', label: 'Document Type' },
      { key: 'region', label: 'Region' },
      { key: 'uniqueSlug', label: 'Unique Slug' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(documents)
      ? documents?.map((document) => ({
          id: document?._id,
          name: (
            <div
              dangerouslySetInnerHTML={{ __html: document?.title }}
              style={{ fontWeight: '500', color: '#000' }}
            />
          ),
          documentType: toCapitalCase(document?.type),
          region: document?.region
            ?.map((region: string) => regionMapping[region] || region)
            .join(', '),
          uniqueSlug: document?.uniqueSlug,
          parentTitle: document?.documentGroupId?.title,
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(document._id)}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const handleRowClick = (id: string, record: any) => {
    console.log('Row clicked:', id, record);
  };

  const updateParamsAndFetchDocuments = (updatedPagination: PaginationType) => {
    setPaginationParams(updatedPagination);
    delayedFetch({
      ...updatedPagination,
      search: updatedPagination.search.trim(),
    });
  };

  const handleCurrentPage = (page: number) => {
    const updatedPagination = { ...paginationParams, page: page };
    updateParamsAndFetchDocuments(updatedPagination);
  };

  const handleLimitChange = (limit: number) => {
    setLimit('documents', limit);
    const updatedPagination = { ...paginationParams, page: 1, limit };
    updateParamsAndFetchDocuments(updatedPagination);
  };

  const onSearchValueChange = (value: string) => {
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      search: value,
    };
    updateParamsAndFetchDocuments(updatedPagination);
  };

  const reducedAllCount = Object.values(analytics).reduce(
    (prev, curr: { key: string; value: string }) => {
      return (prev += curr.value);
    },
    0
  );

  const cardData = Object.keys(analytics).length
    ? docsAnalyticsKeys.map((ak) => ({
        title: toCapitalCase(ak),
        count: ak === 'all' ? reducedAllCount : analytics[ak]?.value || `${0}`,
        key:
          ak === 'go-green'
            ? 'go-green'
            : ak === 'regulations'
              ? 'Regulations'
              : ak === 'all'
                ? null
                : ak,
      }))
    : [];

  const handleDeleteDocument = () => {
    dispatch(deleteDocument(docId));
    if (!apiError) {
      setOpenDeleteModal(false);
      setPaginationParams((prev) => ({
        ...prev,
        page: 1,
      }));
      dispatch(getAllDocumentWithAnalytics({}));
    }
  };

  useHeaderTitle('Documents');
  useEffect(() => {
    const params = new URLSearchParams();
    params.set('page', paginationParams.page.toString());
    params.set('limit', paginationParams.limit.toString());

    if (paginationParams.search) {
      params.set('search', paginationParams.search);
    }

    if (paginationParams.filter?.type) {
      params.set('filter[type]', paginationParams.filter.type);
    }

    navigate(`?${params.toString()}`, { replace: true });
  }, [paginationParams, navigate]);

  const onDocumentsTypeChange = (key: string) => {
    setCardKey(key);
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      filter: { type: key },
    };
    updateParamsAndFetchDocuments(updatedPagination);
  };

  const tabData = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => onDocumentsTypeChange(cardData[index].key)}
    />
  );

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <></>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <SearchInputWrapperStyled>
            <Input
              value={paginationParams.search}
              onChange={(e) => {
                const { value } = e.target;
                onSearchValueChange(value);
              }}
              placeholder="Search Documents"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>
        </div>

        <Table
          tableData={tableData}
          customRow={tabData}
          rowClick={handleRowClick}
          isLoading={loading}
          noData="No data to display."
          maxWidthForCardView="768px"
          headingBgColor="#f0f0f0"
          showMenuIcon={false}
          evenRowBgColor="#fafafa"
        />
        <Pagination
          activePage={paginationParams.page || 1}
          onCurrentPageChange={handleCurrentPage}
          totalPages={pagination?.totalPages || 1}
          limit={getLimit('documents') || 10}
          onLimitChange={handleLimitChange}
        />
      </div>

      <DeleteModal
        title="housebuild.com says"
        deleteText="Are you sure want to delete this document"
        isOpen={openDeleteModal}
        onConfirmDelete={handleDeleteDocument}
        confirmBtnLoading={documentsState.loading}
        onClose={() => setOpenDeleteModal(false)}
      />
    </>
  );
};

export default Documents;
