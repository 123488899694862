import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import downloadCenter from '@/apiConfigs/downloadCenter';
import DeleteModal from '@/components/DeleteModal';
import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';
import requestAPI from '@/utils/requestAPI';

import {
  FolderDetailContainerStyled,
  InfoRowStyled,
  MainContainer,
} from '../../DownloadGroup/Details/style';
import CreateResources from '../CreateResources';

type Props = {};
type ResourceType = {
  title: string;
  position: string;
  type: string;
  downloadCenterCategoryId: {
    title: string;
  };
  downloadCenterGroupId: {
    title: string;
  };
  body: {
    contentType: string;
    content: string;
  };
};

const ResourcesDetails = (_props: Props) => {
  const { resourceId } = useParams();
  const [resource, setResource] = useState<ResourceType>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const navigate = useNavigate();

  const divRef = useRef<HTMLDivElement>(null);
  console.log(divRef);

  const handleDeleModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setDeleteError('');
  };

  const handleDeleteItem = async () => {
    try {
      setIsDeleting(true);
      await requestAPI(downloadCenter.deleteDownloadCenterResource(resourceId));
      toast.success(`Resource has been successfully deleted`);
      navigate('/download/resource');
    } catch (error: any) {
      setDeleteError(error.message);
      console.error(error.message);
      toast.error(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const getSingleResource = async (id: string) => {
    const data = await requestAPI(
      downloadCenter.getSingleDownloadCenterResource(id)
    );
    setResource(data);
  };

  useEffect(() => {
    if (resourceId) {
      getSingleResource(resourceId);
    }
  }, []);

  return (
    <MainContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          marginBottom: '20px',
        }}
      >
        <Typography className="heading">Download Resource Details</Typography>
        <Button variant="gray" onClick={() => setIsEditModalOpen(true)}>
          Edit Resources Detail
        </Button>
        <Button variant="danger" onClick={handleDeleModal}>
          Delete Resource
        </Button>
        <CreateResources
          isOpenModal={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          refreshResource={getSingleResource}
        />
      </div>
      <FolderDetailContainerStyled>
        <InfoRowStyled>
          <Typography className="title">Download Type</Typography>
          <Typography>{resource?.type}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="title">Name</Typography>
          <Typography>{resource?.title}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="title">Position</Typography>
          <Typography>{resource?.position}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="title">Download Folder</Typography>
          <Typography>{resource?.downloadCenterGroupId?.title}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="title">Download Category</Typography>
          <Typography>{resource?.downloadCenterCategoryId?.title}</Typography>
        </InfoRowStyled>
      </FolderDetailContainerStyled>

      {/* <CreateFolders
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
    /> */}
      <div>
        <Typography style={{ marginBottom: '20px' }} size={'h4'}>
          Body
        </Typography>
        {/* <div> */}
        {resource?.body?.contentType === 'image' ? (
          <img
            src={resource?.body?.content}
            height={200}
            width={200}
            style={{ objectFit: 'contain' }}
          />
        ) : resource?.body?.contentType === 'pdf' ? (
          <iframe
            title="pdf"
            src={resource?.body?.content}
            width="100%"
            height="600"
            style={{ border: 'none' }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: resource?.body?.content }} />
          //   <div
          //     className="text-value"
          //     ref={divRef}
          //     dangerouslySetInnerHTML={{
          //       __html: resource.body?.content,
          //     }}
          //   />
        )}
      </div>
      {/* </div> */}
      {isDeleteModalOpen && (
        <DeleteModal
          title={`Delete Resource`}
          deleteText={`Are you sure want to delete this ${resource.title}?`}
          isOpen={isDeleteModalOpen}
          onClose={handleDeleModal}
          onConfirmDelete={handleDeleteItem}
          confirmBtnLoading={isDeleting}
          errorMessage={deleteError}
        />
      )}
    </MainContainer>
  );
};

export default ResourcesDetails;
